import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setIframeResponseData, setIframeLoaded, triggerIframeValid } from '@om/redux/actions/customerVerificationService/selfie';
import {
    getOpenLoadingModal,
    getOpenSuccessfullyVerifiedModal,
    getOpenVerificationModal,
    getOpenSomethingLooksWrongModal,
    getIframeSourceUrl,
    getOpenCmbModal
} from '@om/redux/selectors/customerVerificationService/selfie';
import SuccessfullyVerifiedModal from '../SuccessfullyVerifiedModal';
import SomethingLooksWrongModal from '../SomethingLooksWrongModal';
import {
    IFRAME_SOURCES,
    IIDENTIFII_SKIP_EVENTS,
} from '@om/redux/actions/customerVerificationService/types';
import { getIsSelfiePage } from '@om/redux/selectors/customerVerificationService/routing';
import UnableToVerifyCMBModal from '../UnableToVerifyCMBModal';
import {
    OmModalLoader,
    OmCvsIframe,
} from '@om/component-library-react';

const SelfiePage = () => {
    const dispatch = useDispatch();

    const {
        // eslint-disable-next-line no-unused-vars
        isSelfiePage, // isSelfiePage is passed into the set timeout - DO NOT REMOVE
        openLoadingModal,
        openSuccessfullyVerifiedModal,
        openVerificationModal,
        openSomethingLooksWrongModal,
        IframeSourceUrl,
        openCmbModal } =
        useSelector(createStructuredSelector({
            isSelfiePage: getIsSelfiePage,
            openLoadingModal: getOpenLoadingModal,
            openSuccessfullyVerifiedModal: getOpenSuccessfullyVerifiedModal,
            openVerificationModal: getOpenVerificationModal,
            openSomethingLooksWrongModal: getOpenSomethingLooksWrongModal,
            IframeSourceUrl: getIframeSourceUrl,
            openCmbModal: getOpenCmbModal
        }));

    // TODO: need to investigate useEffect-iframeEventHandler here
    useEffect(() => {
        window.addEventListener('message', event => {
            if (Object.values(IFRAME_SOURCES).includes(event.origin) && !(IIDENTIFII_SKIP_EVENTS.includes(event.data.status))) {
                dispatch(setIframeResponseData(event.data));
            }
        });

        // This will check if the iframe is loaded and the user is still on the selfie page after 20 seconds
        // if not it will redirect to KBA
        setTimeout(isSelfiePage => {
            if (isSelfiePage) {
                dispatch(triggerIframeValid());
            }
        }, 20000);

        // TO DO, look for a better way of hiding these, maybe in application container or in header or footer using redux
        const header = document.getElementsByTagName('om-application-wizard-header')[0];
        const footer = document.getElementsByTagName('om-application-wizard-footer')[0];
        header.style.display = 'none';
        footer.style.display = 'none';

        return () => {
            header.style.display = 'unset';
            footer.style.display = 'unset';
        };
    }, []);

    return (
        <div>
            <OmCvsIframe
                iframeSourceUrl={ IframeSourceUrl }
                onOnIframeLoaded={(event) => {
                    dispatch(setIframeLoaded(true));
                }}
            />
            <OmModalLoader
                slot="loading-modal"
                loaderText={openVerificationModal ? 'Verifying your selfie' : 'Loading'}
                verticalAlignMiddle={true}
                open={ openVerificationModal || openLoadingModal}
            />
            <SuccessfullyVerifiedModal open={ openSuccessfullyVerifiedModal } />
            <SomethingLooksWrongModal open={ openSomethingLooksWrongModal } />
            <UnableToVerifyCMBModal open={ openCmbModal } />
        </div>
    );
};

export default SelfiePage;

