import React, {useEffect} from 'react';
import {useLocation} from '@reach/router'
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {navigate} from 'gatsby';
import OmButton from '../../components/forms/OmButton';
import { OmApplicationWizardFooter } from '@om/component-library-react';

import {
    getActivePageIndex,
    getDoCvsNavigation,
    getDoExitCvsNavigation,
    getParentAppUrl
} from '@om/redux/selectors/customerVerificationService/routing';
import {
    navigatePrevious,
    navigateNext,
    setNavigationDone,
} from '@om/redux/actions/customerVerificationService/routing'
import {PAGES} from '@om/redux/actions/customerVerificationService/types';

const ApplicationWizardFooter = ({
    redirectButton,
    routingNextUrl,
    routingNextEnabled,
}) => {
    const dispatch = useDispatch();
    const {
        activePageIndex,
        doCvsNavigation,
        doExitCvsNavigation,
        parentAppUrl,
    }  = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            doCvsNavigation: getDoCvsNavigation,
            doExitCvsNavigation: getDoExitCvsNavigation,
            parentAppUrl: getParentAppUrl,
        })
    );

    const location = useLocation();
    useEffect(() => {
        if (doExitCvsNavigation) {
            navigate(parentAppUrl);
            return;
        }

        if (!doCvsNavigation) {
            return;
        }

        const currentPageIndex = PAGES.map(page => page.URL).indexOf(location.pathname);
        if (currentPageIndex === activePageIndex) {
            dispatch(setNavigationDone());
            return;
        }

        navigate(PAGES[activePageIndex].URL);
    }, [location, activePageIndex, dispatch, doExitCvsNavigation, doCvsNavigation, parentAppUrl]);

    // TODO: Implement page types for page index and remove magic number
    return (activePageIndex !== 6 &&
        <OmApplicationWizardFooter
            index="activePageIndex"
            totalCount="totalSteps"
        >
            <OmButton
                slot="left"
                onClick={() => dispatch(navigatePrevious())}
                type="text"
                size="small"
                text="Previous"
            />

            {!redirectButton
                ? <OmButton
                    slot="right"
                    onClick={() => dispatch(navigateNext())}
                    type="primary"
                    size="small"
                    text="CONTINUE"
                />
                : <OmButton
                    slot="right"
                    type="primary"
                    size="small"
                    text="CONTINUE"
                    actionType="Url"
                    url={routingNextUrl}
                    disabled={!routingNextEnabled}
                />
            }
        </OmApplicationWizardFooter>
    );
};

export default ApplicationWizardFooter;
