import React from 'react';
import {useDispatch} from 'react-redux';
import {
    setPrivacyPolicyDrawer,
    setSubscriberAgreementDrawer,
    setTermsOfUseDrawer,
} from '@om/redux/actions/customerVerificationService/consent';
import OmButton from '../../../components/forms/OmButton';

const DrawerButton = ({handleClick, children, spacedLeft, spacedRight}) => {
    const spacedClassName = [
        spacedLeft ? 'paragraph-spaced-left' : '',
        spacedRight ? 'paragraph-spaced-right' : '',
    ].join(' ');

    return (
        <OmButton
            className={'open-drawer-button ' + spacedClassName}
            text={children}
            type="text"
            size="small"
            onClick={event => {
                event.stopPropagation();
                handleClick();
            }}
        />
    );
};

const PrivacyPolicyDrawerButton = props => {
    const dispatch = useDispatch();
    return (
        <DrawerButton {...props} handleClick={() => dispatch(setPrivacyPolicyDrawer(true))}>
            Privacy Policy
        </DrawerButton>
    );
};
const SubscriberAgreementDrawerButton = props => {
    const dispatch = useDispatch();
    return (
        <DrawerButton {...props} handleClick={() => dispatch(setSubscriberAgreementDrawer(true))}>
            Subscriber Agreement
        </DrawerButton>
    );
};
const TermsOfUseDrawerButton = props => {
    const dispatch = useDispatch();
    return (
        <DrawerButton {...props} handleClick={() => dispatch(setTermsOfUseDrawer(true))}>
            Terms of Use
        </DrawerButton>
    );
};

export default {
    PrivacyPolicy: PrivacyPolicyDrawerButton,
    SubscriberAgreement: SubscriberAgreementDrawerButton,
    TermsOfUse: TermsOfUseDrawerButton,
};
