import React, {useEffect} from 'react';
import { useLocation } from '@reach/router';
import {useDispatch, useSelector} from 'react-redux';
import {
    setServiceProviderValue,
    setPrivacyPolicyCheckbox,
    setSubscriberAgreementCheckbox,
    setTermsOfUseCheckbox,
} from '@om/redux/actions/customerVerificationService/consent';
import {createStructuredSelector} from 'reselect';
import {
    getPrivacyPolicyIsChecked,
    getSubscriberAgreementIsChecked,
    getTermOfUseIsChecked,
} from '@om/redux/selectors/customerVerificationService/consent';
import PrivacyPolicyDrawer from './PrivicyPolicyDrawer';
import SubscriberAgreementDrawer from './SubscriberAgreementDrawer';
import TermsOfUseDrawer from './TermsOfUseDrawer';
import TermsModal from './TermsModal';
import DrawerButton from './DrawerButton';
import {
    OmCheckBox,
    OmCvsConsentPage,
} from '@om/component-library-react';

const ConsentCheckbox = ({changed, isChecked, id, children}) => {
    return (
        <OmCheckBox
            fieldId={id}
            isButtonCheckbox={false}
            hasIcon={true}
            checked={isChecked}
            value={id}
            id={id}
            onOnCheckBoxChanged={(event) => {
                if (changed) {
                    changed(event.detail);
                }
            }}
        >
            <p slot="text-slot">{children}</p>
        </OmCheckBox>
    );
};

const ConsentPage = () => {
    const dispatch = useDispatch();
    const {
        privacyPolicyIsChecked,
        subscriberAgreementIsChecked,
        termOfUseIsChecked,
    }  = useSelector(
        createStructuredSelector({
            privacyPolicyIsChecked: getPrivacyPolicyIsChecked,
            subscriberAgreementIsChecked: getSubscriberAgreementIsChecked,
            termOfUseIsChecked: getTermOfUseIsChecked,
        })
    );

    // Get service provider data for hook
    const searchUrl = new URLSearchParams(useLocation().search);
    const serviceProviderFromUrl = searchUrl.get('serviceProvider');

    // Switch service provider on mount
    useEffect(() => {
        dispatch(setServiceProviderValue(serviceProviderFromUrl));
    });

    return (
        <OmCvsConsentPage>
            <div slot="side-drawers-and-modals">
                <PrivacyPolicyDrawer />
                <SubscriberAgreementDrawer />
                <TermsOfUseDrawer />
                <TermsModal />
            </div>

            <div slot="titles">
                <h4>Verify your ID</h4>
                <h6>Use our smart application to check your personal details.</h6>
                <hr/>
                <h6>Please read &amp; accept the below</h6>
            </div>

            <div slot="checkboxes">
                <ConsentCheckbox
                    id="privacy-policy"
                    isChecked={privacyPolicyIsChecked}
                    changed={isChecked => dispatch(setPrivacyPolicyCheckbox(isChecked))}
                >
                    I confirm that I consent to the processing of my personal information in line with
                    the<DrawerButton.PrivacyPolicy spacedLeft />.
                </ConsentCheckbox>

                <ConsentCheckbox
                    id="subscriber-agreement"
                    isChecked={subscriberAgreementIsChecked}
                    changed={isChecked => dispatch(setSubscriberAgreementCheckbox(isChecked))}
                >
                    I confirm that I have read, understood and agree with the
                    <DrawerButton.SubscriberAgreement spacedLeft spacedRight />for digital signing.
                </ConsentCheckbox>

                <ConsentCheckbox
                    id="terms-of-use"
                    isChecked={termOfUseIsChecked}
                    changed={isChecked => dispatch(setTermsOfUseCheckbox(isChecked))}
                >
                    I confirm that I have read, understood and agree with the<DrawerButton.TermsOfUse spacedLeft />.
                </ConsentCheckbox>
            </div>
        </OmCvsConsentPage>
    );
};

export default ConsentPage;
