import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {getPrivacyPolicyDrawerIsOpen} from '@om/redux/selectors/customerVerificationService/consent';
import {setPrivacyPolicyDrawer} from '@om/redux/actions/customerVerificationService/consent';
import SideDrawer from '../../../components/SideDrawer';
import DrawerButton from './DrawerButton';
import PrivacyEmail from './PrivacyEmail';

const PrivacyPolicyDrawer = () => {
    const dispatch = useDispatch();
    const {privacyPolicyDrawerIsOpen}  = useSelector(
        createStructuredSelector({
            privacyPolicyDrawerIsOpen: getPrivacyPolicyDrawerIsOpen,
        })
    );

    return (
        <SideDrawer
            isOpen={privacyPolicyDrawerIsOpen}
            onClose={() => dispatch(setPrivacyPolicyDrawer(false))}>
            <h3 className="no-top-margin"><strong>Privacy Policy</strong></h3>
            <h6 className="medium no-top-margin no-bottom-margin">About the Privacy Policy</h6>
            <p>
                We respect your privacy and we are committed to protecting the privacy of the users of this website,
                web-based application and/or mobile application (the “Application”). A summary of the type of
                information that is collected when you use the Application and how this information will be used is
                set out in this Privacy Policy. We seek to employ best practice when it comes to the collection and
                processing of personal information. It is important that you take all necessary and appropriate
                steps to protect your data yourself, for example, by ensuring that all passwords and access codes
                are kept secure. If you follow a link to any other website or use any other application, please
                check their policies before you submit any personal information to those websites/applications.
            </p>
            <p>
                This Privacy Policy is effective from 25 May 2018 onwards and governs both the online and offline
                access to and use of the Application and related services for new and existing users, as it relates
                to the intellectual property rights owner of the Application (the “Licensor”); the Application’s
                content suppliers; and [XXX] with registration number [XXX] and its subsidiaries (collectively known
                as “The Companies”).
            </p>

            <h6 className="medium no-bottom-margin">Your Consent and Agreement</h6>
            <p>
                <strong>BY USING THE APPLICATION AND RELATED SERVICES:</strong>
            </p>
            <ul>
                <li>
                    <strong>
                        YOU CONSENT TO THE COLLECTION, STORAGE, USE AND TRANSFER OF YOUR PERSONAL INFORMATION, WHICH
                        INCLUDES IF APPLICABLE YOUR BIOMETRIC DATA, BY THE COMPANIES FOR PROCESSING AS DESCRIBED IN THIS
                        PRIVACY POLICY; AND
                    </strong>
                </li>
                <li>
                    <strong>
                        YOU FURTHER AGREE THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT THE TERMS AND CONDITIONS OF THIS
                        PRIVACY POLICY, THE <DrawerButton.SubscriberAgreement spacedLeft spacedRight /> AND OUR
                        <DrawerButton.TermsOfUse spacedLeft />.
                    </strong>
                </li>
            </ul>
            <p>
                We reserve the right to change our Privacy Policy as necessity dictates. Continued use of the
                Application and related services implies acceptance of this Privacy Policy,
                the <DrawerButton.SubscriberAgreement spacedLeft spacedRight/> and
                our <DrawerButton.TermsOfUse spacedLeft />, as updated from time to time.
            </p>
            <p>
                All disputes over privacy and your data shall be governed as set out in this Privacy Policy and our
                <DrawerButton.TermsOfUse spacedLeft />.
            </p>

            <h6 className="medium no-bottom-margin">What is personal information?</h6>
            <p>
                Personal information is information which identifies you as an individual, and includes:
            </p>
            <ul>
                <li>
                    your biometric information;
                </li>
                <li>
                    your name if it appears with your other personal information or if your name itself would reveal
                    information about you;
                </li>
                <li>
                    information relating to your race, gender, sex, pregnancy, marital status, national, ethnic or
                    social origin, colour, sexual orientation, age, physical or mental health, well-being,
                    disability, religion, conscience, belief, culture, language and birth;
                </li>
                <li>
                    information relating to your education or medical, financial, criminal or employment history;
                </li>
                <li>
                    any identifying number symbol, e-mail address, physical address, telephone number, location
                    information, online identifier or other particular assignment to you;
                </li>
                <li>
                    your private or confidential correspondence (including correspondence that can reveal your
                    original private or confidential correspondence);
                </li>
                <li>
                    the views or opinions of another individual about you;
                </li>
                <li>
                    any other information defined as “Personal Information” in terms of the Protection of Personal
                    Information Act 4 of 2013 <strong>("POPI")</strong>; and
                </li>
                <li>
                    any other information included under “personal data” and “special categories of personal data”
                    in terms of the General Data Protection Regulation 2016/679 <strong>("GDPR")</strong>.
                </li>
            </ul>

            <h6 className="medium no-bottom-margin">Information collected</h6>
            <p>
                The Companies may collect your personal information through your access and use of the Application,
                during conversations or correspondence with The Companies’ representatives, or when you use the services
                and/or purchase goods and/or complete an online application form.
            </p>
            <p>
                The Companies may collect, store and transfer personal information from you in connection with and for
                the following purposes:
            </p>
            <ul>
                <li>
                    Know Your Customer (KYC) requirements;
                </li>
                <li>
                    Anti-Money Laundering and/or Counter Terrorism Financing Legislation, Rules and Regulations, as
                    relevant to your country;
                </li>
                <li>
                    Financial Intelligence Centre Act 38 of 2001 legislation, as amended (FICA);
                </li>
                <li>
                    Regulation of Interception of Communications and Provision of Communication-Related Information
                    Act 70 of 2002 legislation, as amended (RICA);
                </li>
                <li>
                    The provision of any product or service that The Companies and/or our affiliates may offer now
                    or in the future;
                </li>
                <li>
                    Orders, activations, and registrations for any products or services;
                </li>
                <li>
                    Creation of a user profile on the Application and verification of such profile;
                </li>
                <li>
                    Information requests or complaints;
                </li>
                <li>
                    Direct marketing, newsletter or support subscriptions;
                </li>
                <li>
                    Contest entries or survey participation;
                </li>
                <li>
                    Employment applications;
                </li>
                <li>
                    Registration for events; and
                </li>
                <li>
                    Visits or browsing on The Companies’ websites
                </li>
            </ul>
            <p>
                The Companies may collect, store and transfer the following types of personal information, including,
                but not limited to:
            </p>
            <ul>
                <li>
                    Personal and business contact information, such as name, address, telephone number, and email
                    address;
                </li>
                <li>
                    Biometric information;
                </li>
                <li>
                    All information and documentation required in terms of Anti-Money Laundering and/or Counter
                    Terrorism Financing Legislation, Rules and Regulations, as relevant to your country;
                </li>
                <li>
                    All information and documentation required in terms of FICA;
                </li>
                <li>
                    All information and documentation required in terms of RICA;
                </li>
                <li>
                    All information and documentation required in terms of other legal and/or business requirements
                    relating to KYC;
                </li>
                <li>
                    Financial information, such as your credit/debit card number or other billing information;
                </li>
                <li>
                    Other unique information such as user IDs and passwords, product functionality, product and service
                    preferences, contact preferences, educational and employment history;
                </li>
                <li>
                    Any information necessary in order for us to provide the services you have requested;
                </li>
                <li>
                    Geo-location information such as your IP address or physical location;
                </li>
                <li>
                    Details of the products and services you have purchased from us or which you have enquired about,
                    together with any additional information necessary to deliver those products and services and to
                    respond to your enquiries;
                </li>
                <li>
                    Any additional information relating to you that you provide to us directly through the Application
                    or indirectly through use of the Application, offline or online, through our representatives or
                    otherwise; and
                </li>
                <li>
                    Information you provide to us through our service centre, customer surveys or visits by our
                    representatives from time to time.
                </li>
            </ul>
            <p>
                The Companies may store your personal information manually or electronically. By registering on the
                Application, you also expressly consent to receive direct marketing from The Companies about goods or
                services offered by The Companies and any other affiliate, or entity within The Companies and their
                group of companies. The Companies may also collect information about your computer, including where
                available your IP address, operating system and browser type, for system administration and to report
                aggregate information to our webmasters. This information reveals nothing personal about you. It is
                statistical data about our users' browsing actions and patterns which does not identify any individual
                and allows us to ensure that content from our site is presented in the most effective manner for you and
                for your computer.
            </p>
            <p>
                <strong>
                    BY SUPPLYING YOUR PERSONAL INFORMATION AND USING THE APPLICATION AND RELATED SERVICES, YOU CONSENT
                    TO THE COLLECTION, STORAGE, USE AND TRANSFER OF YOUR PERSONAL INFORMATION, WHICH INCLUDES YOUR
                    BIOMETRIC DATA, BY THE COMPANIES.
                </strong>
            </p>

            <h6 className="medium no-bottom-margin">How the information collected is used</h6>
            <p>
                Personal information provided to The Companies by you will only be used for the purposes it was provided
                as well as for the purposes listed in this Privacy Policy. In light of the nature of the services we
                offer, we may share your information to our third party service providers in order to provide our
                services to you. In the provision of the services, we may also allow other users to access your personal
                information, provided you have agreed to any such user being granted access. Personal information will
                not be sold to third parties, or provided to direct marketing companies or other such organisations
                without your permission. We may disclose your personal information to third parties if we are under a
                duty to disclose or share such information in order to comply with any legal obligation or to protect
                the rights, property or safety of The Companies, its clients or others.
            </p>
            <p>
                The Companies may use your information to:
            </p>
            <ul>
                <li>
                    To carry out our obligations arising from any contracts entered into between you and us;
                </li>
                <li>
                    Provide the services and/or products to you, which may necessitate sharing your personal information
                    with third party service providers located in South Africa as well as outside of South Africa;
                </li>
                <li>
                    Provide third parties with your KYC formation and documentation for the purposes of validating and
                    verifying your identity and residence (or place of business);
                </li>
                <li>
                    Manage our relationship with you and the service providers;
                </li>
                <li>
                    Provide you with access to protected areas of our website;
                </li>
                <li>
                    Communicate with you about The Companies’ products, services or support as well as that of our
                    affiliates and service providers and send marketing communications in connection therewith;
                </li>
                <li>
                    Provide support to you in respect of the products or services;
                </li>
                <li>
                    Improve our products and services and develop new products and services;
                </li>
                <li>
                    Provide personalised promotional offers;
                </li>
                <li>
                    To allow you to participate in interactive features of our service;
                </li>
                <li>
                    Measure performance of marketing initiatives;
                </li>
                <li>
                    Allow you to participate in contests and surveys;
                </li>
                <li>
                    Answer enquiries, provide information, support or advice about existing and new products or
                    services;
                </li>
                <li>
                    Assess and improve the performance and operation of the website;
                </li>
                <li>
                    To keep your contact details up to date and provide updated details to The Companies’ service
                    providers and suppliers where necessary;
                </li>
                <li>
                    Prevent and detect security threats, fraud or other malicious activity; and
                </li>
                <li>
                    To process and respond to any complaint made by you.
                </li>
            </ul>
            <p>
                If you are asked to provide your credit card information, such credit card information is used only for
                payment processing and fraud prevention. This information is not used for any other purpose by us or our
                financial services providers and will not be kept longer than necessary for providing the services,
                unless you ask us to retain your credit card information for future purchases.
            </p>
            <p>
                Demographical and statistical information about user behaviour may be collected and used to analyse the
                popularity and effectiveness of the Application. Any disclosure of this information will be in aggregate
                form and will not identify individual users.
            </p>
            <p>
                If you subscribe for data, news, announcements, alerts or any such information to be emailed to you from
                The Companies, we will hold your details on our database until such time as you chose to unsubscribe
                from this service. You may do this at any stage by selecting the appropriate option in the alerts
                section of this site.
            </p>

            <h6 className="medium no-bottom-margin">Transfer of your personal information to other countries</h6>
            <p>
                Considering the use of cloud service providers and cloud-based technology in the Application, the
                personal information which we collect from you may be transferred to, and stored on a cloud server
                located in South Africa as well as outside of South Africa (for example in the European Union, United
                Kingdom or the United States). Such country may offer a lower level of data protection than in your
                country/region. It may also be processed by staff operating outside your country who work for The
                Companies or for one of our affiliates, partners or associates.
            </p>
            <p>
                We will take all steps reasonably necessary to ensure that your data is treated securely and in
                accordance with this Privacy Policy, which includes hosting on secure servers, encryption of
                transactions and password protection. Where we have given you (or where you have chosen) a password
                which enables you to access certain parts of the Application, you are responsible for keeping this
                password confidential. We ask you not to share a password with anyone.
            </p>
            <p>
                <strong>
                    BY SUPPLYING YOUR PERSONAL INFORMATION, AND USING THE APPLICATION AND RELATED SERVICES, YOU CONSENT
                    TO THE COLLECTION, STORAGE, USE AND TRANSFER OF YOUR PERSONAL INFORMATION, WHICH INCLUDES YOUR
                    BIOMETRIC DATA, BY THE COMPANIES TO AND IN ANOTHER COUNTRY.
                </strong>
            </p>

            <h6 className="medium no-bottom-margin">Storing of personal information</h6>
            <p>
                We will only retain your personal information for as long as is necessary. We undertake to delete your
                personal information which we no longer require. We will keep your personal information for only so long
                as we need it in order to:
            </p>
            <ul>
                <li>
                    to honour your choices;
                </li>
                <li>
                    to fulfil the purposes described in this document; or
                </li>
                <li>
                    as required or permitted by applicable laws and regulations.
                </li>
            </ul>

            <h6 className="medium no-bottom-margin"> Protection of your personal information</h6>
            <p>
                We implement generally accepted standards of technology and operational security in order to protect
                personal information from loss, misuse, alteration or destruction. Unfortunately, the transmission of
                information via the internet is not always completely secure. Please be advised, however, that we cannot
                fully eliminate security risks associated with the storage and transmission of personal information and
                any submission of personal information is at your own risk.
            </p>
            <p>
                Links to other applications and websites: please note that this Privacy Policy does not apply to the
                practices of companies that we do not own or control or to people that we do not employ or manage. Our
                services may provide a link or otherwise provide access to third party sites. We provide these links
                merely for your convenience. We have no control over, do not review, and are not responsible for third
                party sites, their content, or any goods or services available through the third party sites. Our
                Privacy Policy does not apply to third party sites, and any data you provide to third party sites, you
                provide at your own risk. We encourage you to review the privacy policies of any third party sites with
                that you interact with.
            </p>

            <h6 className="medium no-bottom-margin"> Compliance with the Children’s Online Privacy Protection Act</h6>
            <p>
                We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act), we do
                not knowingly collect any information from anyone under 13 years of age. Our website, products and
                services are all directed to people who are at least 13 years old or older.
            </p>
            <p>
                For purposes of GDPR and POPI, note that the minimum age (18 in South Africa, and between 13 and 16 in
                certain European Union Member States) and definition of a “child” may vary based on country/region and
                on local law. If you become aware that a “child” has provided The Companies with personal information
                without parental consent, please contact our Data Protection Officer immediately at <PrivacyEmail />.
                If we become aware that a child has provided us with personal information without parental consent, we
                will take steps to remove the data and cancel the child's account.
            </p>

            <h6 className="medium no-bottom-margin"> Your rights in relation to your personal information </h6>
            <p>
                You may have certain rights under your local law (GDPR, POPI, Promotion of Access to Information Act) in
                relation to the personal information we hold about you. In particular, you may have a legal right to:
            </p>
            <ul>
                <li>
                    Obtain confirmation as to whether we process personal information about you, receive a copy of
                    your personal information and obtain certain other information about how and why we process your
                    personal information;
                </li>
                <li>
                    The right to withdraw consent:
                </li>
                <ul>
                    <li>
                        Where we process personal information based on consent, individuals have a right to withdraw
                        consent at any time, unless the personal information is processed to fulfil legal
                        requirements.
                    </li>
                </ul>
                <li>
                    The right to request for your personal information to be updated, amended or rectified where
                    it is inaccurate (for example, if you change your address) and to have incomplete personal
                    information completed;
                </li>
                <li>
                    The right to delete your personal information in the following cases:
                </li>
                <ul>
                    <li>
                        The personal information is no longer necessary in relation to the purposes for which they
                        were collected and processed;
                    </li>
                    <li>
                        Our legal ground for processing is consent, you withdraw consent and we have no other lawful
                        basis for the processing;
                    </li>
                    <li>
                        Our legal ground for processing is that the processing is necessary for legitimate interests
                        pursued by us or a third party, you object to the processing and we do not have overriding
                        legitimate grounds;
                    </li>
                    <li>
                        You object to processing for direct marketing purposes;
                    </li>
                    <li>
                        Your personal information has been unlawfully processed; or
                    </li>
                    <li>
                        Your personal information must be erased to comply with a legal obligation to which we are
                        subject.
                    </li>
                </ul>
                <li>
                    The right to restrict personal data processing in the following cases:
                </li>
                <ul>
                    <li>
                        For a period enabling us to verify the accuracy of personal information where you contested
                        the accuracy of the personal information;
                    </li>
                    <li>
                        Your personal information have been unlawfully processed and you request restriction of
                        processing instead of deletion;
                    </li>
                    <li>
                        Your personal information is no longer necessary in relation to the purposes for which they
                        were collected and processed but the personal information is required by you to establish,
                        exercise or defend legal claims; or
                    </li>
                    <li>
                        For a period enabling us to verify whether the legitimate grounds relied on by us override
                        your interests where you have objected to processing based on it being necessary for the
                        pursuit of a legitimate interest identified by us.
                    </li>
                </ul>
                <li>
                    The right to object to the processing of your personal information in the following cases:
                </li>
                <ul>
                    <li>
                        Our legal ground for processing is that the processing is necessary for a legitimate
                        interest pursued by us or a third party; or
                    </li>
                    <li>
                        Our processing is for direct marketing purposes.
                    </li>
                    <li>
                        The right to data portability:
                    </li>
                    <li>
                        The right to receive your personal information provided by you to us and the right to send
                        the data to another organisation (or ask us to do so if technically feasible) where our
                        lawful basis for processing the personal information is consent or necessity for the
                        performance of our contract with you and the processing is carried out by automated means.
                    </li>
                </ul>
            </ul>
            <p>
                You can exercise your rights at any time by contacting our Data Protection Officer
                at <PrivacyEmail />. We will respond to your request within 30 days.
            </p>

            <h6 className="medium no-bottom-margin"> Use of cookies</h6>
            <p>
                Cookies are small files that a site or its service provider transfers to your computer’s hard drive
                through your Web browser (if you allow) that enables the sites or service providers systems to recognize
                your browser and capture and remember certain information. We use cookies to understand and save your
                preferences for future visits and compile aggregate data about site traffic and site interaction so that
                we can offer better site experiences and tools in the future. We may contract with third-party service
                providers to assist us in better understanding our site visitors. These service providers are not
                permitted to use the information collected on our behalf except to help us conduct and improve our
                business.
            </p>
            <p>
                Some cookies we use are from third party companies, such as Google Analytics, to provide us with web
                analytics and intelligence about our sites. These companies use programming code to collect information
                about your interaction with our sites, such as the pages you visit, the links you click on and how long
                you are on our sites. This code is only active while you are on the Application. For more information on
                how these companies collect and use information on our behalf, please refer to their privacy policies:
                Google at <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://policies.google.com/?hl=en"
                >
                    Google Privacy & Terms
                </a>.
            </p>
            <p>
                By using the Application you agree that we can place cookies on your device as explained above. If you
                want to remove existing cookies from your device you can do this using your browser options. If you want
                to block future cookies being placed on your device you can change your browser settings to do this.
                Please bear in mind that deleting and blocking cookies will have an impact on your user experience as
                parts of the site may no longer work. Unless you have adjusted your browser settings to block cookies,
                our system will issue cookies as soon as you visit our site or click on a link in a targeted email that
                we have sent you, even if you have previously deleted our cookies.
            </p>

            <h6 className="medium no-bottom-margin"> Applicable Law </h6>
            <p>
                This Privacy Policy will be governed by the laws of the Republic of South Africa. You consent to the
                jurisdiction of the South African Courts for any dispute which may arise out of this Privacy Policy.
            </p>

            <h6 className="medium no-bottom-margin"> Changes to our Privacy Policy </h6>
            <p>
                If we decide to change our Privacy Policy, we will include those changes in the Application.
            </p>

            <h6 className="medium no-bottom-margin">
                Disclosures required in terms of section 43 of the Electronic Communications and Transactions Act 25 of
                2002
            </h6>
            <p>
                Full name: [XXX]<br/>
                Registration number:<br/>
                Country of incorporation:<br/>
                Physical address for receipt of service:<br/>
                Telephone number:<br/>
                Website URL:<br/>
                Email address:<br/>
            </p>
            <p>
                Licensor: Contactable (Pty) Ltd with Registration Number: 2012/154640/07.
            </p>

            <h6 className="medium no-bottom-margin"> Data Protection Questions </h6>
            <p>
                If you have any questions regarding this Privacy Policy, you may contact us at:<br/>
                <strong>Data Protection Officer</strong><br/>
                <strong>Email:</strong> <PrivacyEmail /> <br/>
            </p>
        </SideDrawer>
    );
};

export default PrivacyPolicyDrawer;