import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleCallMeBack } from '@om/redux/actions/customerVerificationService/callMeBack';
import { setActivePageIndex } from '@om/redux/actions/customerVerificationService/routing';
import { PAGE_INDICES } from '@om/redux/actions/customerVerificationService/types';
import OmButton from '../../components/forms/OmButton';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';

const SomethingLooksWrongModal = ({ open }) => {
    const dispatch = useDispatch();
    return (
        <OmApplicationPopupModal
            name="SomethingLooksWrongModal"
            dismissible={false}
            exitable={false}
            open={open}
            closeOnButtonClick={false}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt94f2cb596218bd62/5f2935c8ee8504335e705005/WRONG_CMOS.svg" />
                <h5 className="strong no-bottom-margin">Something looks wrong?</h5>
                <p className="medium no-bottom-margin">
                    <small>We couldn't verify your identity using your selfie</small>
                </p>
            </div>
            <div slot="button">
                <div className="buttons">
                    <OmButton
                        type="primary"
                        size="small"
                        text="continue"
                        onClick={() => dispatch(setActivePageIndex(PAGE_INDICES.KBA_INTERLUDE))}
                    />
                    <OmButton
                        type="secondary"
                        size="small"
                        text="call me back"
                        onClick={() => dispatch(toggleCallMeBack())}
                    />
                </div>
            </div>
        </OmApplicationPopupModal>
    );
};

export default SomethingLooksWrongModal;