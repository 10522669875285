import React from 'react';
import {useDispatch} from 'react-redux';
import {navigateBackToParentApp} from '@om/redux/actions/customerVerificationService/routing';
import OmButton from '../../components/forms/OmButton';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';

const SuccessfullyVerifiedModal = ({
    open,
    onCloseHandler
}) => {
    const dispatch = useDispatch();

    return (
        <OmApplicationPopupModal
            onModalClosed={() => {
                if (onCloseHandler) {
                    onCloseHandler();
                }
            }}
            name="SuccessfullyVerifiedModal"
            dismissible={true}
            exitable={true}
            open={open}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltd9560527c4fab695/5fcf255e97b56110b2b6e289/SUCCESS_CMOS_GREEN.svg" />
                <h5 className="strong no-bottom-margin">You have been successfully verified</h5>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="done"
                    onClick={() => dispatch(navigateBackToParentApp())}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default SuccessfullyVerifiedModal;