import WebComponentsConfig from '../components/WebComponentsConfig';
import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Router} from '@reach/router'
import {useLocation} from '@reach/router'
import {navigate} from 'gatsby';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {HOME_URL, CVS_PAGE_URLS} from '@om/redux/actions/customerVerificationService/types';
import ConsentPage from '../applications/customerVerificationService/consentPage/ConsentPage';
import IDVerificationPage from '../applications/customerVerificationService/idVerificationPage/IDVerificationPage';
import KbaInterludePage from '../applications/customerVerificationService/kbaInterludePage/KbaInterludePage';
import KbaPage from '../applications/customerVerificationService/kbaPage/KbaPage';
import SelfiePage from '../applications/customerVerificationService/selfiePage/SelfiePage';
import ApplicationLayout from '../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../applications/customerVerificationService/ApplicationWizardHeader';
import ApplicationWizardFooter from '../applications/customerVerificationService/ApplicationWizardFooter';
import {setActivePageIndex} from '@om/redux/actions/customerVerificationService/routing';
import {
    getActivePageIndex,
    getDoCvsNavigation,
    getDoExitCvsNavigation,
    getIsKbaQuestionPage,
} from '@om/redux/selectors/customerVerificationService/routing';
import {resetConsentPage} from '@om/redux/actions/customerVerificationService/consent';
import {resetKbaSession} from '@om/redux/actions/customerVerificationService/knowledgeBasedAuthentication';
import {resetIdVerificationPage} from '@om/redux/actions/customerVerificationService/idVerification';
import {PAGES} from '@om/redux/actions/customerVerificationService/types';
import CvsCallMeBackSideDrawer from '../applications/customerVerificationService/CvsCallMeBackSideDrawer';
import { OmApplicationPage } from '@om/component-library-react';

const CvsApplicationPage = ({ data }) => {
    const {
        theme,
        mobile_header_texture,
        kba_background
    } = data.contentstackCustomerVerificationServiceApplication;

    const dispatch = useDispatch();
    const {
        activePageIndex,
        doCvsNavigation,
        doExitCvsNavigation,
        isInKbaPage,
    }  = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            doCvsNavigation: getDoCvsNavigation,
            doExitCvsNavigation: getDoExitCvsNavigation,
            isInKbaPage: getIsKbaQuestionPage,
        })
    );

    // Route guarding
    const location = useLocation();
    useEffect(() => {
        if (doCvsNavigation || doExitCvsNavigation) {
            return;
        }

        const currentPageIndex = PAGES.map(page => page.URL).indexOf(location.pathname);
        if (currentPageIndex > activePageIndex) {
            navigate(PAGES[activePageIndex].URL);
            dispatch(setActivePageIndex(activePageIndex));
        } else if (currentPageIndex < activePageIndex) {
            navigate(PAGES[0].URL);
            dispatch(resetConsentPage());

            if (isInKbaPage) {
                dispatch(resetKbaSession());
            }

            dispatch(resetIdVerificationPage());
            dispatch(setActivePageIndex(0));
        }
    }, [activePageIndex, dispatch, doCvsNavigation, doExitCvsNavigation, isInKbaPage, location.pathname]);

    return (
        <WebComponentsConfig themeContent={theme}>
            <ApplicationLayout>
                <OmApplicationPage>
                    <div slot="header">
                        <ApplicationWizardHeader
                            mobileHeaderTexture={mobile_header_texture}
                            homeUrl={HOME_URL}
                        />
                    </div>

                    <div slot="content-no-sidebar">
                        <Router>
                            <ConsentPage path={CVS_PAGE_URLS.CONSENT} />
                            <IDVerificationPage path={CVS_PAGE_URLS.ID_VERIFICATION} />
                            <SelfiePage path={CVS_PAGE_URLS.SELFIE} />
                            <KbaInterludePage path={CVS_PAGE_URLS.KBA_INTERLUDE} background={kba_background} />
                            <KbaPage path={CVS_PAGE_URLS.KBA} />
                        </Router>
                    </div>

                    <div slot="side-drawer">
                        <CvsCallMeBackSideDrawer />
                    </div>

                    <div slot="footer">
                        <ApplicationWizardFooter />
                    </div>
                </OmApplicationPage>
            </ApplicationLayout>
        </WebComponentsConfig>
    );
};

export const pageQuery = graphql`
    query ModularCvsApplicationPageQuery($id: String!) {
        contentstackCustomerVerificationServiceApplication(id: { eq: $id }) {
            title
            theme {
                ...Theme
            }
            mobile_header_texture {
                ...File
            }
            kba_background {
                ...File
            }
            call_me_back {
                ...CallMeBack
            }
        }
    }
`;

export default CvsApplicationPage;
