import React from 'react';
import { OmSideDrawer } from '@om/component-library-react';

const SideDrawer = ({isOpen, onClose, children, className}) => {
    return (
        <OmSideDrawer
            className={className}
            open={isOpen}
            onOnSideDrawerClosed={() => {
                onClose && onClose();
            }}
        >
            <div slot="side-drawer-content">
                {children}
            </div>
        </OmSideDrawer>
    );
};

export default SideDrawer;
