import React from 'react';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';

const GoodToGoModal = ({ open }) => {
    return (
        <OmApplicationPopupModal
            name="GoodToGoModal" dismissible={false} exitable={false} open={open}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltd9560527c4fab695/5fcf255e97b56110b2b6e289/SUCCESS_CMOS_GREEN.svg" />
                <h5 className="strong no-bottom-margin">All good to go!</h5>
                <p className="medium no-bottom-margin">
                    <small>Your ID has been successfully verified</small>
                </p>
            </div>
        </OmApplicationPopupModal>
    );
};

export default GoodToGoModal;