import React, {useEffect} from 'react';
import {navigate, graphql} from 'gatsby';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    getName,
    getSurname,
    getContactNumber,
    getIsCallMeBackOpen,
    getFormDisabled,
    getButtonText,
    getSubmitSuccess,
    getSubmitError,
    getSubmitPending,
    getRedirectUrl
} from '@om/redux/selectors/customerVerificationService/callMeBack';
import {
    setNameValue,
    setSurnameValue,
    setContactNumberValue,
    setGtmContext,
    sendCallMeBack,
    toggleCallMeBack,
    resetCallMeBack,
} from '@om/redux/actions/customerVerificationService/callMeBack';
import {DEFAULT_VALIDATION_DELAY} from '../config';
import OmInputFieldWrapper from '../../components/forms/OmInputFieldWrapper';
import OmButton from '../../components/forms/OmButton';
import { OmCallMeBackSidebar } from '@om/component-library-react';

const callMeBackClick = (dispatch, formDisabled, submissionSuccess) => {
    if (formDisabled || submissionSuccess) {
        return;
    }

    let GaId;
    try {
        const GAClient = window.ga.getAll();
        if (GAClient && GAClient.length > 0) {
            GaId = GAClient[0].get('clientId');
        }
    } catch (error) {
        GaId = '0.0';
    }

    const gtmContextObj = {
        'url': window.location.href,
        'gaId': GaId
    };

    dispatch(setGtmContext(gtmContextObj));
    dispatch(sendCallMeBack());
};

const CvsCallMeBackSideDrawer = () => {
    const dispatch = useDispatch();

    const {
        cmbSidedrawerOpen,
        name,
        surname,
        phoneNumber,
        formDisabled,
        submissionSuccess,
        submissionError,
        submissionPending,
        buttonText,
        redirectUrl
    }  = useSelector(
        createStructuredSelector({
            cmbSidedrawerOpen: getIsCallMeBackOpen,

            name: getName,
            surname: getSurname,
            phoneNumber: getContactNumber,

            formDisabled: getFormDisabled,
            submissionSuccess: getSubmitSuccess,
            submissionError: getSubmitError,
            submissionPending: getSubmitPending,
            buttonText: getButtonText,
            redirectUrl: getRedirectUrl
        })
    );

    // Bind CMB Side Drawer Custom Events
    useEffect(() => {
        if (!submissionSuccess) {
            return;
        }

        setTimeout(()=> {
            navigate(redirectUrl);
            dispatch(resetCallMeBack());
        }, 2000);
    }, [dispatch, submissionSuccess, redirectUrl]);

    return (
        <OmCallMeBackSidebar
            onOnSideDrawerClosed={() => dispatch(toggleCallMeBack())}
            onSetCallMeBackInfoAndOpen={() => dispatch(toggleCallMeBack())}
            open={cmbSidedrawerOpen}
            show-error={submissionError}
            showThankYou={submissionSuccess}
            privacyPolicyUrl='/privacy-policy'
        >
            <span slot="call-me-back-title">Let's talk</span>
            <span slot="call-me-back-sub-title">
                Share your details and we'll get in touch as soon as possible.
            </span>
            <span slot="call-me-back-inputs">
                <OmInputFieldWrapper
                    isSensitive
                    required
                    preset="first-name"
                    placeholder="Your Name"
                    size="full"
                    formId="callMeBack"
                    name="firstname"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    reduxValue={name}
                    disabled={submissionPending}
                    persistHandler={val => dispatch(setNameValue(val))}
                />
                <OmInputFieldWrapper
                    isSensitive
                    required
                    preset="surname"
                    placeholder="Your Surname"
                    size="full"
                    name="surname"
                    formId="callMeBack"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    reduxValue={surname}
                    disabled={submissionPending}
                    persistHandler={val => dispatch(setSurnameValue(val))}
                />
                <OmInputFieldWrapper
                    isSensitive
                    required
                    preset="cellphone"
                    placeholder="Cellphone Number"
                    size="full"
                    name="cellphone"
                    formId="callMeBack"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    reduxValue={phoneNumber}
                    disabled={submissionPending}
                    persistHandler={val => dispatch(setContactNumberValue(val))}
                />
            </span>

            <OmButton
                slot="call-me-back-button"
                type="primary"
                size="small"
                text={buttonText}
                disabled={formDisabled}
                withIcon={submissionSuccess}
                iconName="check_circle"
                onClick={() => callMeBackClick(dispatch, formDisabled, submissionSuccess)}
            />
        </OmCallMeBackSidebar>
    );
};

export const pageQuery = graphql`
    fragment CallMeBack on Contentstack_call_me_back {
        uid
        call_me_back_product
        call_me_back_product_v2 {
            title
            call_me_back_product
        }
        call_me_back_email
        call_me_back_email_campaign_code
        privacy_policy_url
        call_me_back_extra_fields
    }
`;

export default CvsCallMeBackSideDrawer;
