import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getIsLoadingModalOpen } from '@om/redux/selectors/customerVerificationService/knowledgeBasedAuthentication';
import { getIsVerificationFailed } from '@om/redux/selectors/customerVerificationService/knowledgeBasedAuthentication';
import UnableToVerifyCMBModal from '../UnableToVerifyCMBModal';
import { OmModalLoader, OmCvsKbaInterludePage } from '@om/component-library-react';

const KbaInterludePage = ({
    background
}) => {
    const {loadingModal, initiationFailed}  = useSelector(
        createStructuredSelector({
            loadingModal: getIsLoadingModalOpen,
            initiationFailed: getIsVerificationFailed
        })
    );

    return (
        <OmCvsKbaInterludePage background={background.url}>
            <h3 slot="heading" className="no-top-margin no-bottom-margin">
                We’ll ask you <br/> a few questions
            </h3>
            <h5 slot="subheading">
                Please answer the questions correctly to continue.
            </h5>
            <div slot="error-modal">
                <UnableToVerifyCMBModal
                    open={initiationFailed}
                />
            </div>
            <div slot="loading-modal">
                <OmModalLoader
                    loaderText="Loading"
                    verticalAlignMiddle={true}
                    open={loadingModal}
                />
            </div>
        </OmCvsKbaInterludePage>
    );
};

export default KbaInterludePage;
