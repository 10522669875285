import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {getTermOfUseDrawerIsOpen} from '@om/redux/selectors/customerVerificationService/consent';
import {setTermsOfUseDrawer} from '@om/redux/actions/customerVerificationService/consent';
import SideDrawer from '../../../components/SideDrawer';
import DrawerButton from './DrawerButton';
import PrivacyEmail from './PrivacyEmail';

const TermsOfUseDrawer = () => {
    const dispatch = useDispatch();
    const {termOfUseDrawerIsOpen}  = useSelector(
        createStructuredSelector({
            termOfUseDrawerIsOpen: getTermOfUseDrawerIsOpen,
        })
    );

    return (
        <SideDrawer
            isOpen={termOfUseDrawerIsOpen}
            onClose={() => dispatch(setTermsOfUseDrawer(false))}>
            <div slot="side-drawer-content">
                <h3 className="no-top-margin"><strong>Terms of Use</strong></h3>

                <h6 className="medium no-top-margin no-bottom-margin">Terms and Conditions</h6>
                <p>
                    We welcome you as a user of the services provided via this website, web-based application and/or
                    mobile application (the “Application”). We are providing the services to you, subject to these Terms
                    of Use, the <DrawerButton.PrivacyPolicy spacedLeft spacedRight /> and the
                    <DrawerButton.SubscriberAgreement spacedLeft spacedRight/> for digital signing (collectively known
                    as the “Terms”).
                </p>
                <p>
                    The Terms, as may be amended from time to time, are effective from 25 May 2018 and onwards and
                    govern
                    both the online and offline access to and use of the Application and related services for new and
                    existing users, as it relates to the intellectual property rights owner of the Application (the
                    “Licensor”); the Application’s content suppliers; and [XXX] with registration number [XXX] and its
                    subsidiaries (collectively known as “The Companies”).
                </p>

                <h6 className="medium no-bottom-margin"> Consent and Agreement </h6>
                <p>
                    The Terms apply to your use of the Application and related services. You must read the Terms
                    carefully, and we recommend that you print and keep a copy for your future reference.
                </p>
                <p>
                    <strong>
                        BY ACCESSING, USING AND/OR BROWSING THE APPLICATION AND RELATED SERVICES:
                    </strong>
                </p>
                <ul>
                    <li>
                        <strong>
                            YOU CONSENT TO THE COLLECTION, STORAGE, USE AND TRANSFER OF YOUR PERSONAL INFORMATION, WHICH
                            INCLUDES IF APPLICABLE YOUR BIOMETRIC DATA, BY THE COMPANIES FOR PROCESSING AS DESCRIBED IN
                            THE <DrawerButton.PrivacyPolicy spacedLeft />; AND
                        </strong>
                    </li>
                    <li>
                        <strong>
                            YOU FURTHER AGREE THAT YOU HAVE READ, UNDERSTOOD AND ACCEPT THE TERMS AND CONDITIONS OF THIS
                            TERMS OF USE, THE <DrawerButton.PrivacyPolicy spacedLeft spacedRight /> AND THE
                            <DrawerButton.SubscriberAgreement spacedLeft />.
                        </strong>
                    </li>
                </ul>
                <p>
                    We reserve the right to change the Terms as necessity dictates. Continued use of the Application and
                    related services implies acceptance of the Terms, as updated from time to time.
                </p>

                <h6 className="medium no-bottom-margin"> Your Personal Information </h6>
                <p>
                    As noted above, we are providing the services to you, subject to the Terms, which include our
                    <DrawerButton.PrivacyPolicy spacedLeft/>. Please read our <DrawerButton.PrivacyPolicy spacedLeft/>.
                </p>

                <h6 className="medium no-bottom-margin"> Electronic Certification – Law Trust </h6>
                <p>
                    Via an associated relationship with LAWtrust, The Companies makes use of Digital Electronic
                    Certificates and Advanced Electronic Certificates in its technology to secure and authenticate
                    documents and digital identity. As noted above, we are providing the services to you, subject to the
                    Terms, which include the <DrawerButton.SubscriberAgreement spacedLeft/>.
                    Please read our <DrawerButton.SubscriberAgreement spacedLeft/>.
                </p>

                <h6 className="medium no-bottom-margin"> Registration </h6>
                <p>
                    You must be older than 18 years of age to access and use the Application.
                </p>
                <p>
                    As an unregistered user you might be able to browse the services offered on the website. However
                    when you choose to make use of the services offered on the Application, you will be required to
                    login with your username and password. If you do not have a username and password you will be
                    required to register on the Application. Registration requires filling in a short form which will
                    create login details. Once the registration is complete and you are logged in you will then be able
                    to make use of the services. To the extent that there are any charges associated with the use of the
                    services, credit card details might be required. All first time users of the Application will be
                    verified and authenticated through a process called XDS verification. The information you provide
                    must be accurate and complete. You may not impersonate anyone else, choose names that are offensive
                    or that violate anyone’s rights. If you don’t follow these rules, we may cancel your account. Every
                    person must register their own account and you are not entitled to permit anyone other than you to
                    use your account.
                </p>
                <p>
                    By registering on the Application, you expressly consent to The Companies collecting the personal
                    information you provide, and to The Companies processing your personal information as required to
                    provide the services requested. This will be done in compliance with the
                    <DrawerButton.PrivacyPolicy spacedLeft/>. By registering on the Application, you also expressly
                    consent to receive direct marketing from The Companies about goods or services offered by The
                    Companies and any other affiliate, or entity within The Companies and their group of companies.
                </p>

                <h6 className="medium no-bottom-margin"> Password / Account Security </h6>
                <p>
                    You are responsible for maintaining the confidentiality of your password and account and any
                    activities that occur under your account. We will assume that anyone using the Application or
                    transacting through your account is you. The Companies shall not be liable to any person for any
                    loss or damage which may arise as a result of any failure by you to protect your password or
                    account.
                </p>
                <p>
                    You hereby indemnify The Companies from any loss, liability, cost or expense resulting directly or
                    indirectly from any unauthorised person accessing your account, and waive any right to take action
                    against The Companies, its directors, employees or affiliates in respect of any loss, liability,
                    cost or expense resulting directly or indirectly from any unauthorised person accessing your
                    account, or from any authorised access to your account.
                </p>
                <p>
                    If you find out that someone has used your account without your permission, you must report it
                    immediately to: <PrivacyEmail />.
                </p>

                <h6 className="medium no-bottom-margin"> Accuracy of content </h6>
                <p>
                    The Companies have taken all reasonable steps to ensure that all data described or depicted on the
                    Application and any other information displayed on the Application is current, complete and
                    accurate. However content errors may occur. The information displayed on the Application is provided
                    without any guarantees, conditions or warranty as to its accuracy or completeness.
                </p>
                <p>
                    To the extent permitted by applicable law, The Companies disclaim all representations and
                    warranties, express or implied, that content or information displayed in or on the Application is
                    accurate, complete, up-to-date and/or does not infringe the rights of any third party. Data provided
                    by or through The Companies and/or the Application is provided by users, and not verified or audited
                    by The Companies.
                </p>
                <p>
                    The use of these contents is forbidden in those places where the law does not allow this disclaimer
                    to take full effect.
                </p>
                <p>
                    The Companies reserve the right to:
                </p>
                <ul>
                    <li>
                        Modify or withdraw, temporarily or permanently, the Application (or any part of) with or without
                        notice to you and you confirm that we shall not be liable to you or any third party for any
                        modification to or withdrawal of the Application; and/or
                    </li>
                    <li>
                        Change the Terms from time to time, and your continued use of the Application (or any part
                        thereof) following such change shall be deemed to be your acceptance of such change. It is your
                        responsibility to check regularly to determine whether the Terms have been changed. If you do
                        not agree to any change to the Terms then you must immediately stop using the Application;
                        and/or
                    </li>
                    <li>
                        Change the services from time to time.
                    </li>
                </ul>
                <p>
                    We will use our reasonable endeavours to maintain the Application. You will not be eligible for any
                    compensation because you cannot use any part of the Application or because of a failure, suspension
                    or withdrawal of all or part of the Application due to circumstances beyond our control.
                </p>

                <h6 className="medium no-bottom-margin"> Copyright and Intellectual Property Rights </h6>
                <p>
                    Copyright and all intellectual property rights in relation to the Application, including in all
                    materials, texts, source code, drawings, graphics, icons and any data made available on the
                    Application (collectively “materials”) are owned by the intellectual property rights owner of the
                    Application (the “Licensor”) and is licensed to [XXX] through a licensing agreement between the
                    licensor, its content suppliers and [XXX] and therefore protected by international intellectual
                    property laws. Accordingly, any unauthorised copying, reproduction, reverse engineering,
                    retransmission, distribution, dissemination, sale, publication, broadcast or other circulation or
                    exploitation of such material or any component thereof will constitute an infringement of such
                    copyright and other intellectual property rights. You may only use the materials or any component
                    thereof for your information purposes when access thereto is permitted by the Licensor. The
                    trademarks, names, logos and service marks (collectively “trademarks”) displayed on the Application
                    are registered and unregistered trademarks of The Companies, as the case may be. Nothing contained
                    on the Application should be taken as granting any licence or right to use any trademark without the
                    prior written permission of The Companies.
                </p>
                <p>
                    We grant you a limited license to access the Application and its related services, which is non-
                    exclusive and non-transferrable. This license does not permit any of the following:
                </p>
                <ul>
                    <li>
                        Resale or commercial use of the Application or its contents or its related services;
                    </li>
                    <li>
                        Collection and use of any product listings, descriptions, or prices;
                    </li>
                    <li>
                        Collection and use of the content for any use other your own personal use;
                    </li>
                    <li>
                        Derivative use of the Application or its contents or its related services; any downloading or
                        copying of account information for the benefit of another merchant; or
                    </li>
                    <li>
                        Any use of data mining, robots, or similar data gathering and extraction tools.
                    </li>
                </ul>

                <h6 className="medium no-bottom-margin"> Links to other websites </h6>
                <p>
                    External links may be provided as part of our service and for your convenience, but they are beyond
                    the control of The Companies and no representation is made by The Companies as to their content,
                    source or any risks that you may incur through linking to the other website. Use or reliance on any
                    external links provided is at your own risk and we accept no liability in respect of such use. When
                    visiting external links you must refer to the external terms and conditions of use.
                </p>
                <p>
                    You may not link to the Application, in any manner, or frame any content of the Application in any
                    other manner or otherwise use the content without the express prior written permission of The
                    Companies.
                </p>

                <h6 className="medium no-bottom-margin"> Use of the Application </h6>
                <p>
                    You are not entitled to permit anyone other than you to use your account. Only you and The Companies
                    shall be entitled to enforce the Terms. No third party shall be entitled to enforce any of the
                    Terms.
                </p>
                <p>
                    The Companies shall not be liable to you or any third party for any loss or damage of whatsoever
                    nature in the event that you make use of the services offered through the Application. You hereby
                    indemnify The Companies from any loss, liability, cost or expense resulting directly or indirectly
                    from your use of the Application and/or services available thereto, and waive any right to take
                    action against The Companies in respect of any loss, liability, cost or expense resulting directly
                    or indirectly from your use of the Application and/or services available thereto.
                </p>
                <p>
                    By accessing and using the Application, you warrant and represent to The Companies that you are
                    legally entitled to do so and to provide the data required. You further warrant that all the details
                    you have given are true and complete and that you are legally capable of concluding this
                    transaction.
                </p>
                <p>
                    If you are younger than 18 years of age, you warrant that you have the consent of your parent or
                    legal guardian to enter into this agreement with The Companies or that you have obtained legal
                    status in another manner.
                </p>
                <p>
                    'Deep-linking', 'embedding' or using analogous technology is strictly prohibited. Unauthorized use
                    of the Application and/or the materials contained on the Application may violate applicable
                    copyright, trademark or other intellectual property laws or other laws.
                </p>
                <p>
                    We may in our sole discretion at any time suspend or terminate your participation through the
                    Application and/or the operation of the Application without prior notice to you and without the need
                    to give you reasons for such termination. We will not be liable to you or any third party for doing
                    so.
                </p>
                <p>
                    You agree and warrant <span className="underline">not to</span>:
                </p>
                <ul>
                    <li>
                        Access or attempt to access any part of the Application by any means other than through the
                        interface provided by us;
                    </li>
                    <li>
                        Engage in any activity that interferes with, or disrupts, the Application or the servers and
                        networks that host the Application;
                    </li>
                    <li>
                        Use data mining, robots, screen scraping or similar data gathering and extraction tools on the
                        Application;
                    </li>
                    <li>
                        Copy, reproduce, republish, upload, post, transmit or distribute and information in, from or
                        connected to the Application or its services;
                    </li>
                    <li>
                        Frame or utilise framing techniques to enclose any trade mark, logo, or other proprietary
                        information (including images, text, page layout, or form) belonging to The Companies and its
                        affiliates without our prior written consent.
                    </li>
                    <li>
                        Attempt to circumvent, disable or interfere with the security related features of the
                        Application or features that prevent or restrict use of copying any content or enforce
                        limitations on the use of the Application or content therein.
                    </li>
                </ul>
                <p>
                    You agree to comply with all applicable laws, statutes, ordinances and regulations pertaining to
                    your access to and/or use of the Application, the services, and/or use of your customer profile, or
                    any part of these, and/or your posting of content and material (including text, files, images and
                    photographs).
                </p>
                <p>
                    You may not yourself or through a third party –
                </p>
                <ul>
                    <li>
                        Use the Application for harmful, unlawful or illegal purposes, including, without limitation to:
                    </li>
                    <ul>
                        <li>
                            Impersonate any other person or use a false or unauthorised name so as to create a false
                            identity and/or e-mail address or to misrepresent the origin or identity of any
                            communications;
                        </li>
                        <li>
                            Collect, harvest or otherwise obtain Personal Information, (including personal information
                            as defined in POPI and Personal Data as defined in GDPR), relating to other users;
                        </li>
                        <li>
                            Create, store and/or send unsolicited commercial communications; or conduct any fraudulent
                            activity including any "pyramid scheme", "ponzi scheme" or "chain letter".
                        </li>
                    </ul>
                    <li>
                        Use any customer profile in a manner so as to advertise or promote your or any third party’s
                        products or services and/or impersonate another person;
                    </li>
                    <li>
                        Copy (other than for backup, archival or disaster recovery purposes), reproduce, translate,
                        adapt, vary, modify, distribute, lease, license, sub-license, encumber or in any other way deal
                        with any portion of the Application for any reason and in any manner, unless it is consistent
                        with the intent and purpose of these Terms;
                    </li>
                    <li>
                        Decompile, disassemble or reverse engineer any portion of the Application;
                    </li>
                    <li>
                        Collect service listings, descriptions or other information displayed on the Application;
                    </li>
                    <li>
                        Write and/or develop any derivative of the Application or any other software program based on
                        the Application;
                    </li>
                    <li>
                        Adapt, modify or enhance the Application. In the event of a user effecting any adaptation(s),
                        modification(s) or enhancement(s) to the Application in breach of this clause, such
                        adaptation(s), modification(s) and enhancement(s) hereby vest exclusively in us and you hereby
                        assign all rights in and to, and waive any and all moral rights in and to, such adaptation(s),
                        modification(s) and enhancement(s), which assignment we accept;
                    </li>
                    <li>
                        Without our prior written consent, provide, disclose, divulge or make available to or permit the
                        use of or give access to the Application by persons other than you;
                    </li>
                    <li>
                        Remove any identification, trade mark, copyright or other notices from the Application and/or
                        any personalised item, it being specifically recorded, acknowledged and agreed that trademarks
                        proprietary to or licensed to us will automatically be incorporated in all customer profiles;
                    </li>
                    <li>
                        Establish a hyperlink, frame, metatag or similar reference, whether electronically or otherwise
                        (collectively "linking"), to the Application or any subsidiary pages;
                    </li>
                    <li>
                        Use the Application and/or any personalised item to transfer files that contain viruses,
                        Trojans, bombs, time-locks, phishing programmes or other harmful programmes; and/or penetrate or
                        attempt to penetrate the Application’s security measures.
                    </li>
                </ul>

                <h6 className="medium no-bottom-margin"> Security </h6>
                <p>
                    We implement generally accepted standards of technology and operational security in order to protect
                    personal information from loss, misuse, alteration or destruction. Unfortunately, the transmission
                    of information via the internet is not always completely secure. Please be advised, however, that we
                    cannot fully eliminate security risks associated with the storage and transmission of personal
                    information and any submission of personal information is at your own risk.
                </p>

                <h6 className="medium no-bottom-margin"> Warranties </h6>
                <p>
                    <strong>
                        THE COMPANIES MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                        OPERATION OF THE APPLICATION OR THE INFORMATION, CONTENT, MATERIALS, AND/ OR PRODUCTS AND
                        SERVICES INCLUDED ON THE APPLICATION. YOU EXPRESSLY AGREE THAT YOUR USE OF THE APPLICATION IS AT
                        YOUR SOLE RISK.
                    </strong>
                </p>
                <p>
                    <strong>
                        TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                        PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE APPLICATION, ITS SERVERS, OR E-MAIL SENT FROM US
                        ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE WILL NOT BE LIABLE IN ANY WAY FOR ANY
                        DAMAGES OF ANY KIND ARISING FROM THE USE OF THE APPLICATION AND FORUMS, INCLUDING, BUT NOT
                        LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.
                    </strong>
                </p>
                <p>
                    <strong>
                        NEITHER THE COMPANIES, NOR ITS DIRECTORS, EMPLOYEES, OFFICERS, SUPPLIERS, SERVICE PROVIDERS OR
                        SUBSIDIARIES, MAKE ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND (INCLUDING BUT NOT LIMITED TO
                        EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES, AS TO THE OPERATION OF THE APPLICATION OR THE
                        INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THE APPLICATION). YOU EXPRESSLY AGREE
                        THAT YOUR USE OF THE APPLICATION IS AT YOUR SOLE RISK.
                    </strong>
                </p>
                <p>
                    <strong>
                        IN NO EVENT SHALL, THE COMPANIES’ DIRECTORS, EMPLOYEES, OFFICERS, SERVICE PROVIDERS OR
                        SUBSIDIARIES BE LIABLE FOR LOST PROFITS OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES
                        ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE APPLICATION, OUR SERVICES OR THE TERMS AND
                        CONDITIONS (HOWSOEVER ARISING, INCLUDING BUT NOT LIMITED TO NEGLIGENCE).
                    </strong>
                </p>

                <h6 className="medium no-bottom-margin"> Indemnity </h6>
                <p>
                    <strong>

                        IN ADDITION TO ANY OTHER INDEMNITIES PROVIDED IN THESE TERMS, YOU HEREBY UNCONDITIONALLY AND
                        IRREVOCABLY INDEMNIFY THE COMPANIES, ITS AFFILIATED COMPANIES, OFFICERS, AGENTS, DIRECTORS AND
                        EMPLOYEES AND AGREE TO HOLD US FREE FROM ALL LOSS, DAMAGES, CLAIMS AND/OR COSTS, OF WHATSOEVER
                        NATURE SUFFERED OR INCURRED BY US OR INSTITUTED AGAINST US FOR INFRINGEMENT OF ANY RIGHT,
                        WHETHER THE CLAIM OR ACTION ARISES FROM DELICT OR CONTRACT, OR ANY INFRINGEMENT OF WHATSOEVER
                        NATURE, ARISING OUT OF OR PURSUANT TO THE EXERCISE BY YOU OF YOUR RIGHTS OR THE FULFILMENT OR
                        BREACH BY YOU OF ANY OF YOU OBLIGATIONS UNDER THESE TERMS OR ANY OTHER REQUIREMENTS THAT WE MAY
                        IMPOSE FROM TIME TO TIME, INCLUDING, WITHOUT LIMITATION, ALL LOSS, DAMAGES, CLAIMS AND/OR COSTS
                        SUFFERED OR INCURRED BY US OR INSTITUTED AGAINST US AS A DIRECT OR INDIRECT RESULT OF YOUR USE
                        OF THE APPLICATION, AND SUCH INDEMNITY SHALL EXTEND TO THE REASONABLE COSTS THAT MAY BE INCURRED
                        BY THE COMPANIES IN DEFENDING ANY ACTION INSTITUTED AGAINST THE COMPANIES.
                    </strong>
                </p>
                <p>
                    <strong>
                        YOUR USE OF THE APPLICATION, THE SERVICES AND/OR YOUR CUSTOMER PROFILE IS ENTIRELY AT YOUR OWN
                        RISK AND YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM THE USE THEREOF. THE
                        COMPANIES DO NOT ACCEPT LIABILITY THAT MAY ARISE IN CONNECTION WITH THE UPLOADING, REPRODUCTION,
                        DISPLAY, USE, DISTRIBUTION OR SHARING BY YOU OF YOUR CUSTOMER PROFILE.
                    </strong>
                </p>
                <p>
                    <strong>
                        THE COMPANIES, ITS AFFILIATES, SHAREHOLDERS, DIRECTORS, AGENTS, CONSULTANTS OR EMPLOYEES SHALL
                        NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT,
                        SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, HOWSOEVER ARISING (WHETHER IN AN ACTION
                        ARISING OUT OF CONTRACT, STATUTE, DELICT OR OTHERWISE) RELATED TO THE ACCESS TO AND/OR USE OF,
                        OR THE INABILITY TO ACCESS OR USE, THE APPLICATION, ANY PERSONALISED ITEM AND/OR ANY LINKED
                        APPLICATION, THE CONTENT THEREOF, ANY FUNCTIONALITY THEREOF OR INFORMATION CONTAINED THEREIN,
                        EVEN IF WE KNOW OR SHOULD REASONABLY HAVE KNOWN OR ARE EXPRESSLY ADVISED THEREOF.
                    </strong>
                </p>

                <h6 className="medium no-bottom-margin"> Address for Service </h6>
                <p>
                    The address for service for all purposes relating to these Terms including the giving of any notice,
                    the payment of any sum, the serving of any process, is the physical address set out above. The
                    Companies shall be entitled from time to time, by giving notice to you, to vary its physical address
                    for service to any other physical address. The Companies shall be entitled to vary its address for
                    service by notice on the Application, and accordingly you undertake to ensure you have the correct
                    service address.
                </p>

                <h6 className="medium no-bottom-margin"> Entire Agreement </h6>
                <p>
                    The Terms set out the entire agreement between you and us related to the subject matter hereof, and
                    supersedes any and all prior terms, conditions, warranties and/or representations to the fullest
                    extent permitted by law.
                </p>

                <h6 className="medium no-bottom-margin"> Other legal notices </h6>
                <p>
                    The Companies shall be entitled to make use of all the data you provide for purposes of providing
                    the services offered through the Application. The Companies shall be entitled to cede, assign and
                    delegate all or any of its rights and obligations in terms of these Terms.
                </p>
                <p>
                    All provisions of the Terms are, irrespective of the manner in which they have been grouped together
                    or linked grammatically, severable from each other. Any provision of these Terms which is or becomes
                    unenforceable in any jurisdiction, whether due to voidness, invalidity, illegality, unlawfulness or
                    for any reason whatsoever, shall, in such jurisdiction only and only to the extent that it is so
                    unenforceable, be excluded from the agreement between us and the remaining provisions of these Terms
                    shall remain in full force and effect.
                </p>
                <p>
                    Should The Companies be prevented from fulfilling any of its obligations to you as a result of any
                    event outside the control of The Companies, then those obligations shall be deemed to have been
                    suspended to the extent that, and for as long as, The Companies is so prevented from fulfilling them
                    and your corresponding obligations shall be suspended to the corresponding event. In the event that
                    such event continues for more than 14 (fourteen) days after it has first occurred, then The
                    Companies shall be entitled (but not obliged) to terminate all of its rights and obligations in
                    terms of or arising out of these Terms by giving notice to you. An event outside of the control of
                    The Companies shall mean any event or circumstance whatsoever which is not within the reasonable
                    control of The Companies including, but not limited to, vis major, casus fortuitous, any act of God,
                    strike, theft, riots, explosion, insurrection or other similar disorder, war (whether declared or
                    not) or military operations, the downtime of any external telecommunications line, power failure,
                    international restrictions, any requirement or any international authority, any requirement of any
                    government or other competent local authority, any court order, export control or shortage or
                    transport facilities.
                </p>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of the Republic of South
                    Africa without giving effect to any principles of conflict of law. You hereby consent to exclusive
                    jurisdiction of the High Court of South Africa in respect of any disputes arising in connection with
                    the services referred to herein, or the Terms or any matter related to or in connection therewith.
                </p>
                <p>
                    For purposes of calculating any time periods GMT +2 shall apply.
                </p>

                <h6 className="medium no-bottom-margin">
                    Disclosures required in terms of section 43 of the Electronic Communications and Transactions Act 25
                    of 2002
                </h6>

                <p>
                    Full name: [XXX]<br/>
                    Registration number:<br/>
                    Country of incorporation:<br/>
                    Physical address for receipt of service:<br/>
                    Telephone number:<br/>
                    Website URL:<br/>
                    Email address:<br/>
                </p>

                <p> Licensor: Contactable (Pty) Ltd with Registration Number: 2012/154640/07. </p>
            </div>
        </SideDrawer>
    );
};

export default TermsOfUseDrawer;
