import React from 'react';
import { OmApplicationTooltip, OmIcon } from '@om/component-library-react';

const ApplicationTooltip = ({
    id,
    className,
    iconClass,
    borderLeft,
    small,
    iconName,
    tooltipContent
}) => {
    return (
        <OmApplicationTooltip
            id={id}
            className={className}
            borderLeft={borderLeft}
            small={small}
        >
            <div className={iconClass} slot="icon">
                <OmIcon iconName={iconName} />
            </div>
            <p slot="tooltip-content" dangerouslySetInnerHTML={{__html: tooltipContent}}></p>
        </OmApplicationTooltip>
    );
};

export default ApplicationTooltip;