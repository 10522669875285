import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleCallMeBack } from '@om/redux/actions/customerVerificationService/callMeBack';
import OmButton from '../../components/forms/OmButton';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';

const UnableToVerifyCMBModal = ({
    open,
    onCmbHandler
}) => {
    const dispatch = useDispatch();

    return (
        <OmApplicationPopupModal
            onModalClosed={() => {
                if (onCmbHandler) {
                    onCmbHandler();
                }
            }}
            name="UnableToVerifyCMBModal"
            dismissible={false}
            exitable={false}
            open={open}
            closeOnButtonClick={false}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt7379af7d0a25dd73/5ee9f203557e3a516a2d9a29/desktop_monitor_smiley.svg" />
                <h5 className="strong no-top-margin no-bottom-margin">Oops!</h5>
                <h5 className="strong no-top-margin no-bottom-margin">We are unable to verify your information</h5>
                <p className="medium no-bottom-margin">
                    <small>Someone can call you back to assist you</small>
                </p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="call me back"
                    onClick={() => dispatch(toggleCallMeBack())}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default UnableToVerifyCMBModal;
