import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import OmDropdownField from '../../../components/forms/OmDropdownField';
import SuccessfullyVerifiedModal from '../SuccessfullyVerifiedModal';
import UnableToVerifyCMBModal from '../UnableToVerifyCMBModal';
import {
    getActiveQuestion,
    getActiveOptions,
    getAnswer,
    getActiveQuestionNumber,
    getIsLoadingModalOpen,
    getIsVerificationSuccessful,
    getIsVerificationFailed,
    getTimeRemaining,
    getSubmissionCompleted
} from '@om/redux/selectors/customerVerificationService/knowledgeBasedAuthentication';
import {
    setAnswerValue,
    setTimerValue,
    setQuestionExpired
} from '@om/redux/actions/customerVerificationService/knowledgeBasedAuthentication';
import {
    OmModalLoader,
    OmTimerWidget,
    OmKbaQuestionPage,
} from '@om/component-library-react';

const KbaPage = () => {
    const dispatch = useDispatch();

    const {
        formComplete,
        question,
        options,
        answer,
        activeNumber,
        loadingModal,
        verificationSuccessful,
        verificationFailed,
        timeRemaining
    } = useSelector(
        createStructuredSelector({
            formComplete: getSubmissionCompleted,
            question: getActiveQuestion,
            options: getActiveOptions,
            answer: getAnswer,
            activeNumber: getActiveQuestionNumber,
            loadingModal: getIsLoadingModalOpen,
            verificationSuccessful: getIsVerificationSuccessful,
            verificationFailed: getIsVerificationFailed,
            timeRemaining: getTimeRemaining
        })
    );

    return (
        <OmKbaQuestionPage key={activeNumber}>
            <h4 slot="question" className="sessioncamhidetext no-top-margin no-bottom-margin">
                {question}
            </h4>
            <div className="dropdown-container" slot="dropdown-timer">
                <div className="dropdown">
                    <OmDropdownField
                        isSensitive
                        useNativeMobileDropdown="true"
                        required="true"
                        size="medium"
                        formId="kbaForm"
                        placeholder="Select an option"
                        displayLabel="false"
                        name="answer"
                        align="left"
                        onSelectHandler={event => dispatch(setAnswerValue(event.detail.id))}
                        reduxValue={answer}
                        options={ options &&
                            options.map(option => ({ value: option.answerId, label: option.text }))
                        }
                    />
                </div>
                <div className="timer-container">
                    <OmTimerWidget
                        onTimeRemainingUpdate={event => { dispatch(setTimerValue(event.detail)) }}
                        onComplete={() => { dispatch(setQuestionExpired()) }}
                        active={!formComplete}
                        timeLimit="60"
                        slot="timer"
                        timeRemaining={timeRemaining}
                    />
                </div>
                <OmModalLoader
                    slot="loading-modal"
                    loaderText="Loading"
                    verticalAlignMiddle={true}
                    open={loadingModal}
                />
                <SuccessfullyVerifiedModal
                    slot="success-modal"
                    open={verificationSuccessful}
                />
                <UnableToVerifyCMBModal
                    slot="failure-modal"
                    open={verificationFailed}
                />
            </div>
        </OmKbaQuestionPage>
    );
};

export default KbaPage;
