import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {getSubscriberAgreementDrawerIsOpen} from '@om/redux/selectors/customerVerificationService/consent';
import {setSubscriberAgreementDrawer} from '@om/redux/actions/customerVerificationService/consent';
import SideDrawer from '../../../components/SideDrawer';

const SubscriberAgreementDrawer = () => {
    const dispatch = useDispatch();
    const {subscriberAgreementDrawerIsOpen}  = useSelector(
        createStructuredSelector({
            subscriberAgreementDrawerIsOpen: getSubscriberAgreementDrawerIsOpen,
        })
    );

    return (
        <SideDrawer
            isOpen={subscriberAgreementDrawerIsOpen}
            onClose={() => dispatch(setSubscriberAgreementDrawer(false)) }>
            <h3 className="no-top-margin"><strong>Subscriber Agreement</strong></h3>
            <h6 className="medium no-top-margin no-bottom-margin">
                <strong>
                    YOU MUST AGREE TO THE FOLLOWING TERMS AND CONDITIONS IN ORDER TO BE ISSUED WITH A DIGITAL
                    CERTIFICATE FOR SIGNING PURPOSES
                </strong>
            </h6>
            <p>
                By signing this agreement, you agree to familiarise yourself and fully comply with the terms and
                conditions which you are bound by as contained in the LAWtrust AATL CA Certificate Practice Statement
                (CPS), Electronic Communications and Transactions Act 25 of 2002 (ECT Act) and ECT Act Accreditation
                Regulations available in the repository
                on  <a className="link" href="https://www.lawtrust.co.za/repository/">
                    www.lawtrust.co.za/repository/
                </a> as well as any other requirements set out by LAWtrust in the digital signing of documents.
            </p>
            <p>
                By completing and submitting this Subscriber Agreement to LAWtrust, together with the supporting
                documentation required, you, the applicant, are applying to be issued with a digital certificate (once
                issued, you will then be referred to as a subscriber) to enable you to sign data messages.
            </p>
            <p>
                Your resultant electronic signature is deemed to be your advanced electronic or AATL signature by virtue
                of LAWTrusts accreditation of its authentication products and services in terms of section 37 of the ECT
                Act.
            </p>
            <p>
                The issuance and subsequent use by you of the digital certificate is subject to the following terms and
                conditions:
            </p>

            <h6 className="medium no-bottom-margin">
                <strong>
                    YOU, THE APPLICANT WARRANT, ACCEPT AND AGREE THAT:
                </strong>
            </h6>

            <ul>
                <li>
                    All information submitted by you in support of this digital certificate application is true and correct,
                    and you shall subject yourself to a face to face identification by LAWtrust in the establishment of your
                    identity;
                </li>
                <li>
                    The digital certificate issued to you and its subsequent use is personal to you, the subscriber (i.e.
                    the person named in the digital certificate), to enable you to create advanced electronic signatures;
                </li>
                <li>
                    You shall exercise all reasonable care to manage and retain control of the private key associated to the
                    public key listed in the digital certificate, including retaining control of any pass-phrase, and
                    prevent disclosure to a person not authorized to create your advanced electronic signature, and such
                    duty shall continue throughout the period of validity of the digital certificate and during any period
                    of suspension of the digital certificate;
                </li>
                <li>
                    The utilisation of a digital signature will be legally binding in the same manner as a signature on
                    paper. You will not use your digital certificate for any unlawful purpose;
                </li>
                <li>
                    To all who reasonably rely on the information contained in the digital certificate that: (i) you
                    rightfully hold the private key corresponding to the public key listed in the digital certificate; (ii)
                    all representations made by you to LAWtrust and material to the information listed in the digital
                    certificate are true; and (iii) all information in the digital certificate of which you have knowledge
                    is true;
                </li>
                <li>
                    You shall request LAWtrust to revoke your digital certificate immediately on you becoming aware, or when
                    you ought reasonably to have become aware, of any inaccurate information contained therein, or suspected
                    loss, disclosure or compromise of your digital certificates associated private key;
                </li>
                <li>
                    You shall not monitor, interfere with, or reverse-engineer any technical implementation required for the
                    creation of your digital certificate, or its use, or otherwise in any manner seek to compromise the
                    security thereof;
                </li>
                <li>
                    This agreement constitutes a legally binding document enforceable by law. Any failure by you to comply
                    with your above obligations constitutes a material breach by you of this Subscriber Agreement;
                </li>
                <li>
                    You accept and bear the risk of a compromise of your digital certificate and/or its associated private
                    key, which may or may not be detected; and you accept and bear the risk of your compromised digital
                    certificate and/or its associated private key being used to impersonate you and/or your electronic
                    signature; and
                </li>
                <li>
                    Under no circumstances shall LAWtrust, its licensors, suppliers and staff be liable, whether in
                    contract, warranty, delict, vicarious liability or any other form of liability whatsoever, for any
                    general, direct, indirect, incidental, special or consequential damages (including without limitation
                    any loss of profits, loss of data, interruption, computer failure or pecuniary loss) arising out of your
                    use or inability to use any of LAWtrusts authentication products or services.
                </li>
            </ul>

            <h6 className="medium no-bottom-margin">
                <strong>
                    IN ORDER TO REGISTER YOUR DIGITAL CERTIFICATE, LAWTRUST NEEDS TO COLLECT THE FOLLOWING PERSONAL
                    INFORMATION:
                </strong>
            </h6>

            <ul>
                <li>
                    Your name and surname, telephone number, email address, and a copy of your ID, security pins and
                    application passwords;
                </li>
                <li>
                    LAWtrust needs to collect this information in order to positively verify your identity and to create
                    your electronic signature profile;
                </li>
                <li>
                    We will keep your personal information confidential and only use it for internal purposes, to
                    contact you, to reset a password if needed and for technical purposes;
                </li>
                <li>
                    By providing LAWtrust with the required personal information as detailed above, you consent to the
                    lawful processing of the personal information by LAWtrust for purposes of validation, enrolment and
                    issuing a digital certificate or any such further purpose which is related or necessary to achieve
                    the abovementioned purpose or comply with legislative requirements; and
                </li>
                <li>
                    LAWtrust will take appropriate, reasonable technical and organisational measures to prevent loss of,
                    damage to or unauthorised destruction of personal information and unlawful access to or processing
                    of personal information.
                </li>
            </ul>
        </SideDrawer>
    );
};

export default SubscriberAgreementDrawer;