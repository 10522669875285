import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import OmInputFieldWrapper from '../../../components/forms/OmInputFieldWrapper';
import {
    setIDValue
} from '@om/redux/actions/customerVerificationService/idVerification';
import {
    getID,
    getWaitingForVerificationCall,
    getVerificationCallError,
    getOpenGoodToGoModal,
} from '@om/redux/selectors/customerVerificationService/idVerification';
import UnableToVerifyCMBModal from '../UnableToVerifyCMBModal';
import GoodToGoModal from '../GoodToGoModal';
import ApplicationTooltip from '../../../components/applications/ApplicationTooltip';
import {DEFAULT_VALIDATION_DELAY} from '../../config';

import { OmModalLoader, OmCvsIdPage } from '@om/component-library-react';

const IDVerificationPage = () => {
    const dispatch = useDispatch();

    const {
        id,
        loadingModalStatus,
        callError,
        openGoodToGoModal
    } = useSelector(
        createStructuredSelector({
            id: getID,
            loadingModalStatus: getWaitingForVerificationCall,
            callError: getVerificationCallError,
            openGoodToGoModal: getOpenGoodToGoModal,
        })
    );

    return (
        <div>
            <OmCvsIdPage>
                <h4 slot="heading" className="no-top-margin">Enter your <strong>ID number</strong></h4>
                <div slot="form">
                    <p>{callError}</p>
                    <OmInputFieldWrapper
                        isSensitive
                        required
                        id="idNumber"
                        size="full"
                        align="left"
                        placeholder="ID Number"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        reduxValue={id}
                        persistHandler={(event) => dispatch(setIDValue(event))}
                    />
                    <ApplicationTooltip
                        iconName="information_outline"
                        tooltipContent="Your security is extremely important to us. All your information is constantly protected."
                    />
                </div>
                <OmModalLoader
                    slot="loading-modal"
                    loaderText="Verifying your details"
                    verticalAlignNiddle={true}
                    open={loadingModalStatus}
                />
                <div slot="error-modal">
                    <UnableToVerifyCMBModal
                        open={callError}
                    />
                </div>
                <div slot="good-to-go-modal">
                    <GoodToGoModal open={ openGoodToGoModal }/>
                </div>
            </OmCvsIdPage>
        </div>
    );
};

export default IDVerificationPage;
