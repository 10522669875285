import React from 'react';
import {Link} from '@reach/router';
import {useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {
    getProgressBarSteps,
    getIsKbaQuestionPage,
    getProgressBarIndex
} from '@om/redux/selectors/customerVerificationService/routing';
import {
    getActiveQuestionNumber,
    getTotalNumberOfQuestions
} from '@om/redux/selectors/customerVerificationService/knowledgeBasedAuthentication';
import OmButton from '../../components/forms/OmButton';
import {
    OmApplicationWizardHeader,
    Om1ColLayout,
    OmIcon,
    OmApplicationProgressBar,
} from '@om/component-library-react';

const ApplicationWizardHeader = ({
    mobileHeaderTexture,
    homeUrl
}) => {
    const {
        progressBarSteps,
        isKbaQuestionPage,
        progressBarIndex,
        questionNumber,
        totalQuestionNumbers
    }  = useSelector(
        createStructuredSelector({
            progressBarSteps: getProgressBarSteps,
            isKbaQuestionPage: getIsKbaQuestionPage,
            progressBarIndex: getProgressBarIndex,
            questionNumber: getActiveQuestionNumber,
            totalQuestionNumbers: getTotalNumberOfQuestions
        })
    );

    return (
        <OmApplicationWizardHeader
            helpNumber="0860 665 463"
            headerTexture={mobileHeaderTexture ? mobileHeaderTexture.url : ''}
        >

            <div slot="wizard-title">
                {isKbaQuestionPage && <p>Question {questionNumber} of {totalQuestionNumbers}</p>}
                <h5><strong>Smart Application</strong></h5>
            </div>

            {/*<span slot="contact-button">*/}
            {/*    <OmButton*/}
            {/*        type="secondary"*/}
            {/*        size="small"*/}
            {/*        text="Call me back"*/}
            {/*        click="toggleCallMeBack(callMeBackGeneric)"/>*/}
            {/*</span>*/}

            <Link slot="close-button" to={homeUrl}>
                <OmIcon className="close-icon" iconName="close"/>
            </Link>
            
            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="Customer Verification Service"
                        closable="true"
                        steps={progressBarSteps}
                        index={progressBarIndex}
                        hideDesktop={true}
                        hideMobile={false}
                        questionsInsteadOfSteps={isKbaQuestionPage}
                    >
                        <OmButton
                            actionType="url"
                            slot="close-application"
                            type="text"
                            size="small"
                            url="/"
                            text="CLOSE"
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;
