import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeTermsModal} from '@om/redux/actions/customerVerificationService/consent';
import {createStructuredSelector} from 'reselect';
import {getTermsModalIsOpen} from '@om/redux/selectors/customerVerificationService/consent';
import {toggleCallMeBack} from '@om/redux/actions/customerVerificationService/callMeBack';
import OmButton from '../../../components/forms/OmButton';
import { OmApplicationPopupModal } from '@om/component-library-react';

const TermsModal = () => {
    const dispatch = useDispatch();
    const {termsModalIsOpen}  = useSelector(
        createStructuredSelector({
            termsModalIsOpen: getTermsModalIsOpen,
        })
    );

    return (
        <OmApplicationPopupModal
            name={TermsModal}
            dismissible={false}
            exitable={false}
            open={termsModalIsOpen}
            onModalClosed={() => {
                dispatch(closeTermsModal());
            }}
        >
            <div slot="content">
                <h5 className="strong no-bottom-margin no-top-margin">Please accept the terms</h5>
                <p className="medium no-bottom-margin">
                    <small>You need to tick all the checkboxes to continue.</small>
                </p>
            </div>
            <div slot="button">
                <div className="buttons">
                    <OmButton
                        type="primary"
                        size="small"
                        text="okay"
                    />
                    <OmButton
                        type="secondary"
                        size="small"
                        text="call me back"
                        onClick={() => dispatch(toggleCallMeBack())}
                    />
                </div>
            </div>
        </OmApplicationPopupModal>
    );
};

export default TermsModal;